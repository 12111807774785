import React from 'react';
import InnerBanner from 'components/inner-banner';

const Services = () => {
	return (
		<>
			{/* <!-- inner banner --> */}
			<InnerBanner title="Services" />

			<section class="w3l-features-8">
				{/* <!-- /features --> */}
				<div class="features">
					<div class="wrapper">
						<div
							class="cwp23-title"
							style={{
								width: '100%',
								paddingBottom: 20,
							}}>
							<h3
								style={{
									maxWidth: '100%',
								}}>
								TMT manages warehouses for OEMs, Tier 2 suppliers to feed components on shift basis requirements. Operating with about
								60000 sq feet area located strategically 10 km off from Hosur to meet the demands of customer in and around Hosur.
							</h3>
						</div>
						<div class="fea-gd-vv">
							<div class="float-top">
								<a href="single.html">
									<img src={require("../../assets/images/e1.jpg")} class="img-responsive" alt="" />
								</a>
								<div class="float-lt feature-gd">
									<div class="icon">
										{' '}
										<span class="fa fa-chain-broken" aria-hidden="true"></span>
									</div>
									<div class="icon-info">
										<h3>
											<a href="single.html">Power & Energy</a>{' '}
										</h3>
										<p>Our operations cover regular audits, safety drills and trainings for our staff at various levels</p>
									</div>
								</div>
							</div>
							<div class="float-top">
								<a href="single.html">
									<img src={require("../../assets/images/e2.jpg")} class="img-responsive" alt="" />
								</a>
								<div class="float-lt feature-gd">
									<div class="icon">
										{' '}
										<span class="fa fa-plug" aria-hidden="true"></span>
									</div>
									<div class="icon-info">
										<h3>
											<a href="single.html">Mechanical Works</a>{' '}
										</h3>
										<p>Management of warehousing for Imported parts and raw materials</p>
									</div>
								</div>
							</div>
							<div class="float-top">
								<a href="single.html">
									<img src={require("../../assets/images/e3.jpg")} class="img-responsive" alt="" />
								</a>
								<div class="float-lt feature-gd">
									<div class="icon">
										{' '}
										<span class="fa fa-database" aria-hidden="true"></span>
									</div>
									<div class="icon-info">
										<h3>
											<a href="single.html">Petroleum Refinery</a>{' '}
										</h3>
										<p>Value added services: Transferring to returnable bins , labeling and packing</p>
									</div>
								</div>
							</div>
							<div class="float-top">
								<a href="single.html">
									<img src={require("../../assets/images/e4.jpg")} class="img-responsive" alt="" />
								</a>
								<div class="float-lt feature-gd">
									<div class="icon">
										{' '}
										<span class="fa fa-fire-extinguisher" aria-hidden="true"></span>
									</div>
									<div class="icon-info">
										<h3>
											<a href="single.html">Oil and Gas</a>{' '}
										</h3>
										<p>VMI and WMS integrated raw material warehousing- Under process</p>
									</div>
								</div>
							</div>
							<div class="float-top">
								<a href="single.html">
									<img src={require("../../assets/images/e5.jpg")} class="img-responsive" alt="" />
								</a>
								<div class="float-lt feature-gd">
									<div class="icon">
										{' '}
										<span class="fa fa-truck" aria-hidden="true"></span>
									</div>
									<div class="icon-info">
										<h3>
											<a href="single.html">Logistics Services</a>{' '}
										</h3>
										<p>Strict safety and environment regulations</p>
									</div>
								</div>
							</div>
							<div class="float-top">
								<a href="single.html">
									<img src={require("../../assets/images/g6.jpg")} class="img-responsive" alt="" />
								</a>
								<div class="float-lt feature-gd">
									<div class="icon">
										{' '}
										<span class="fa fa-industry" aria-hidden="true"></span>
									</div>
									<div class="icon-info">
										<h3>
											<a href="single.html">General Industry</a>{' '}
										</h3>
										<p>Dedicated and shared warehouse and transport solutions</p>
									</div>
								</div>
							</div>
							{/* <!-- <div class="float-top">
                        <a href="single.html"><img src="../../assets/images/g3.jpg" class="img-responsive" alt=""/></a>
                        <div class="float-lt feature-gd">
                            <div class="icon"> <span class="fa fa-database" aria-hidden="true"></span></div>
                            <div class="icon-info">
                                <h3><a href="single.html">Industrial</a> </h3>
                                <p> Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor
                                    incididunt ut labore et. </p>
                            </div>
                        </div>
                    </div>
                    <div class="float-top">
                        <a href="single.html"><img src="../../assets/images/g4.jpg" class="img-responsive" alt=""/></a>
                        <div class="float-lt feature-gd">
                            <div class="icon"> <span class="fa fa-chain-broken" aria-hidden="true"></span></div>
                            <div class="icon-info">
                                <h3><a href="single.html">Automotive</a> </h3>
                                <p> Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor
                                    incididunt ut labore et. </p>
                            </div>
                        </div>
                    </div>
                    <div class="float-top">
                        <a href="single.html"><img src="../../assets/images/g5.jpg" class="img-responsive" alt=""/></a>
                        <div class="float-lt feature-gd">
                            <div class="icon"> <span class="fa fa-plug" aria-hidden="true"></span></div>
                            <div class="icon-info">
                                <h3><a href="single.html">Engineering</a> </h3>
                                <p> Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor
                                    incididunt ut labore et. </p>
                            </div>
                        </div>
                    </div> --> */}
						</div>
					</div>
				</div>
				{/* <!-- //features --> */}
			</section>
		</>
	);
};

export default Services;
