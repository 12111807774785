import React, { useEffect } from "react";
import AOS from "aos";
import { offerData } from "constants/app-constant";
import "./offers.scss";

const Offers = () => {
  // useEffect(() => {
  //   AOS.init({
  //     duration: 500,
  //   });
  //   AOS.refresh();
  // }, []);
  return (
    <section data-aos-anchor-placement="center-center">
      <div className="container featured-main">
        <div className="shopex-title">
          <p>What We Offer!</p>
        </div>
        <div className="shopex-card-main">
          {offerData?.map((data, index) => (
            <div key={index} className="shopex-card">
              <img src={data?.image} alt="cctv support" />
              <p className="shopex-card-title">{data?.title}</p>
              <p className="shopex-card-content">{data?.content}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Offers;
