import React from 'react';
// import Hekto from "../../assets/images/Hekto.svg";
import './footer.scss';

const Footer = () => {
	return (
		<section class="main-footer">
			<div class="container footer-master">
				<div class="footer-main">
					<div className="contact-area">
						<div class="footer-content-left">{/* <img src={Hekto} alt="hekto" /> */}</div>
						{/* <div class="footer-main-input">
							<input type="text" placeholder="Enter Your Email" />
							<button>Sign Up</button>
						</div> */}
						<div class="footer-contact contact-details">
							<p>Contact info</p>
							<p>
								<a className="link-with-icon" href="https://wa.me/917092979999" rel="nofollow noopener" target="_blank">
									7092979999
								</a>
							</p>
							<p>
								<a href="mailto:info@mgtechpark.com" class="info-email">
									info@mgtechpark.com
								</a>
							</p>
							<p>83/90, M.A.Palanisamy Street, Near HDFC Bank, NSR Road, Saibaba Colony, Coimbatore, Tamil Nadu 641038</p>
						</div>
					</div>
					<div class="footer-main-middle">
						<p class="footer-title">Categories</p>
						<p class="footer-content">Laptops & Computers</p>
						<p class="footer-content">CCTV & Biometrics</p>
					</div>
					{/* <div class="footer-main-middle">
						<p class="footer-title">My Account</p>
						<p class="footer-content">Discount</p>
						<p class="footer-content">Returns</p>
						<p class="footer-content">Orders History</p>
						<p class="footer-content">Order Tracking</p>
						<p class="footer-content">Order Tracking</p>
					</div>
					<div class="footer-main-middle">
						<p class="footer-title">Pages</p>
						<p class="footer-content">Blog</p>
						<p class="footer-content">Browse the Shop</p>
						<p class="footer-content">Pre-Built Pages</p>
						<p class="footer-content">Visual Composer Elements</p>
						<p class="footer-content">Waterproof Headphones</p>
					</div> */}
				</div>
			</div>
		</section>
	);
};

export default Footer;
