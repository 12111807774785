import React from 'react';
// import Hekto from "../../Assets/images/Hekto.svg";
// import Search from "../../Assets/images/search.svg";
import './nav-bar.scss';

const Navbar = () => {
	return (
		<nav>
			<div className="container nav-bottom-main">
				<div className="nav-bottom-logo">
          <div className="logo-name">MG Tech Park</div>
          {/* <img src={Hekto} alt="Hekto" /> */}
        </div>
				<div className="nav-bottom">
					<ul>
						<li>
							<a href="#">Home</a>
						</li>
						<li>
							<a href="#">About</a>
						</li>
						<li>
							<a href="#">Services</a>
						</li>
						{/* <li>Blog</li>
            <li>Shop</li> */}
						<li>
							<a className="link-with-icon" href="https://wa.me/917092979999" rel="nofollow noopener" target="_blank">
								<span>Contact</span>
								<img className="whatsapp-icon" alt="contact" src="./images/whatsapp.png" />
							</a>
						</li>
					</ul>
				</div>
				{/* <div className="nav-bottom-input">
          <div className="nav-bottom-inputmain">
            <input type="text" />
            <button>
              <img src={Search} alt="search" />
            </button>
          </div>
        </div> */}
			</div>
		</nav>
	);
};

export default Navbar;
