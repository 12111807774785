import Home from "pages/home";
import About from "pages/about";
import Services from "pages/services";
import Contact from "pages/contact";

export const ROUTES = [
	{
		route: '/',
		Component: Home,
	},
	{
		route: '/about',
		Component: About,
	},
	{
		route: '/services',
		Component: Services,
	},
	{
		route: '/contact',
		Component: Contact,
	},
];

// import cctvIcon from "../Assets/images/security-camera.png";
// import solarPanel from "../Assets/images/solar-panel.png";
// import biometric from "../Assets/images/biometric.png";
// import delivery from "../Assets/images/shopex-delivery.svg";
// import hands from "../Assets/images/shopex-hands.svg";
// import badge from "../Assets/images/shopex-badge.svg";
// import support from "../Assets/images/shopex-call.svg";

export const cardData = [
  {
    title: "CCTV services",
    content: "CCTV services for both residential and commercial services",
    image: 'http://demo.mgtechpark.com/static/media/security-camera.da0b9b48f00d6027838d.png',
  },
  // {
  //   title: "Solar panels",
  //   content: "Solar panels for both residential and commercial services",
  //   image: "http://demo.mgtechpark.com/static/media/solar-panel.f008ce8778a585c9e5e9.png",
  // },
  {
    title: "Biometric",
    content: "Biometric services for companies",
    image: "http://demo.mgtechpark.com/static/media/biometric.410dd516e8205cfef3fd.png",
  },
];

export const offerData = [
  {
    title: "Delivery Assured",
    content: "We'll deliver the products at rite time promised",
    image: "http://demo.mgtechpark.com/static/media/shopex-delivery.81755ba06842165fc37d59a1a196648d.svg",
  },
  {
    title: "Guarantee Assured",
    content: "We're guarantee for your products delivered by us",
    image: "http://demo.mgtechpark.com/static/media/shopex-hands.ddf4a26715bd16d8fedf2902ec124740.svg",
  },
  {
    title: "Products Assured",
    content: "The products we'll deliver will be well known brands",
    image: "http://demo.mgtechpark.com/static/media/shopex-badge.fe53a1b185922fb3aef0ca44cfa22933.svg",
  },
  {
    title: "Support Assured",
    content: "We'll deliver 24/7 support to our customers",
    image: 'http://demo.mgtechpark.com/static/media/shopex-call.0762e3c8a96ce0d2afc7c31ab094edb2.svg',
  },
];
