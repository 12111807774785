import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './footer';
import Navbar from './nav-bar';

const PageLayout = () => {
	return (
		<>
			<Navbar />
			<Outlet />
			{/* <!-- footer-29 block --> */}
			<Footer />
		</>
	);
};

export default PageLayout;
