import React, { useEffect } from 'react';
// import Light from "../../Assets/images/light-img.svg";
// import Cam from "../../Assets/images/cam.svg";
import AOS from 'aos';
import './home.scss';
import Services from 'components/services';
import Offers from 'components/offers';

const Home = () => {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);

	return (
		<>
			<main className="landing-master">
				{/* <image className="light-left" src={Light} alt="light-image" /> */}
				<div className="container landing-main">
					<div className="landing-left">
						<p className="landing-top">Best Security Services....</p>
						<p className="landing-sub">Safe & Secure Home For Your Family</p>
						<p className="landing-content">
							We also handle commercial security services like Cctv, Biometric attendance & solar products for more info contact us
						</p>
						<div>
							{/* <button className="landing-button-left">Contact us</button> */}
							<a className="landing-button-link" href="https://wa.me/917092979999" rel="nofollow noopener" target="_blank">Contact us</a>
							{/* <button className="landing-button">Contact us</button> */}
							{/* <button className="landing-button-left">Contact us</button>
							<button className="landing-button">Get quotes</button> */}
						</div>
					</div>
					<div data-aos="fade-left" className="landing-right">
						<img
							className="landing-right-img"
							src={'http://demo.mgtechpark.com/static/media/cam.9ad7c3c549c328d37b981d4261632485.svg'}
							alt="drone"
						/>
					</div>
				</div>
			</main>
			<Services />
			<Offers />
		</>
	);
};

export default Home;
