import InnerBanner from 'components/inner-banner';
import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
	return (
		<>
			{/* <!-- inner banner --> */}
			<InnerBanner title="About"/>

			{/* <!-- Content-with-photo23 block --> */}
			<section className="w3l-content-with-photo-23">
				<div id="cwp23-block">
					<div className="wrapper">
						<div
							className="cwp23-title"
							style={{
								width: '100%',
								paddingBottom: 20,
							}}>
							<h3 style={{ maxWidth: '100%' }}>
								At TMT –Thiruchendur Murugan Thunai Warehousing, we understand that efficient logistics are the backbone of successful
								supply chain management. As a trusted and reliable third-party logistics (3PL) warehouse, we pride ourselves on
								offering comprehensive and tailored warehousing solutions to meet the unique needs of our clients We provide
								end-to-end logistics services that encompass warehousing, transportation, and fulfillment. Our strategically located
								warehouses are equipped with advanced inventory management and Material handling systems, allowing us to streamline
								operations and optimize space utilization while ensuring the utmost security and safety of your products
							</h3>
						</div>
						<div className="d-grid cwp23-content">
							<div className="cwp23-img">
								<img
									src={require('../../assets/images/e2.jpg')}
									className="img-responsive"
									alt=""
									style={{
                                        height: "100%",
                                        objectFit: "cover"
                                    }}
								/>
							</div>
							<div className="cwp23-text">
								<div className="cwp23-title">
									<h3>Mission & Vision</h3>
								</div>
								<div
									className="cwp23-text-cols"
									style={{
                                        gridTemplateColumns: "1fr"
                                    }}>
									{/* <!-- <div className="column">
                                <h4>History</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor </p>
                            </div> --> */}
									<div>
										<h4>Mission</h4>
										<p>
											Our mission is to provide superior and comprehensive logistics solutions to our clients, helping them
											optimize their supply chain operations and achieve their business goals. We will leverage our expertise,
											state-of-the-art technology, and committed team to deliver exceptional warehousing, distribution, and
											value-added services. We are dedicated to building long-term partnerships with our clients, based on
											trust, integrity, and mutual success
										</p>
									</div>
									<div className="column">
										<h4>Vision</h4>
										<p>
											Vision Statement: To be the leading 3PL (Third-Party Logistics) warehouse that sets the benchmark for
											excellence in logistics and supply chain management. We aim to be recognized as a trusted partner by our
											clients, known for our reliability, efficiency, and customer-centric approach
										</p>
									</div>
									{/* <!-- <div className="column">
                                <h4>Strategy</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor </p>
                            </div>
                            <div className="column">
                                <h4>Machine</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor </p>
                            </div> --> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- Content-with-photo23 block --> */}
			<section className="w3l-features">
				<div className="grid top-bottom">
					<div className="wrapper">
						<div className="middle-section grid-column text-center">
							<div className="three-grids-columns">
								<span className="fa fa-lightbulb-o"></span>
								<h4>Who We Are</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.</p>
							</div>
							<div className="three-grids-columns">
								<span className="fa fa-shirtsinbulk"></span>
								<h4>Our Security</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.</p>
							</div>
							<div className="three-grids-columns">
								<span className="fa fa-user-secret"></span>
								<h4>Our Process</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- teams 32 block --> */}
			<section className="w3l-teams-32-main">
				<div className="teams-32">
					<div className="wrapper">
						<div className="d-grid grid-col-4 align-center main-contteam-32">
							<div className="team-main-19">
								<div className="column-19">
									<a href="team-single.html">
										<img className="img-responsive" src={require("../../assets/images/te-1.jpg")} alt=" " />
									</a>
								</div>
								<div className="right-team-9">
									<h6>
										<a href="team-single.html" className="title-team-32">
											Mick Hussey
										</a>
									</h6>
									<p className="sm-text-32">Founder</p>
									<p className="sub-paragraph midd-para-team">Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
									<div className="buttons-teams">
										<Link to="/">
											<span className="fa fa-facebook"></span>
										</Link>
										<Link to="/">
											<span className="fa fa-twitter"></span>
										</Link>
										<Link to="/">
											<span className="fa fa-instagram"></span>
										</Link>
									</div>
								</div>
							</div>
							<div className="team-main-19">
								<div className="column-19">
									<a href="team-single.html">
										<img className="img-responsive" src={require("../../assets/images/te-2.jpg")} alt=" " />
									</a>
								</div>
								<div className="right-team-9">
									<h6>
										<a href="team-single.html" className="title-team-32">
											Martin Guptil
										</a>
									</h6>
									<p className="sm-text-32">Designer</p>
									<p className="sub-paragraph midd-para-team">Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
									<div className="buttons-teams">
										<Link to="/">
											<span className="fa fa-facebook"></span>
										</Link>
										<Link to="/">
											<span className="fa fa-twitter"></span>
										</Link>
										<Link to="/">
											<span className="fa fa-instagram"></span>
										</Link>
									</div>
								</div>
							</div>
							{/* <!-- <div className="team-main-19">
                        <div className="column-19">
                            <a href="team-single.html"><img className="img-responsive" src="assets/images/te-3.jpg"
                                    alt=" "></a>
                        </div>
                        <div className="right-team-9">
                            <h6><a href="team-single.html" className="title-team-32">Steve Smith</a></h6>
                            <p className="sm-text-32">Developer</p>
                            <p className="sub-paragraph midd-para-team">Lorem ipsum dolor sit amet, consectetur adipisicing
                                elit</p>
                            <div className="buttons-teams">
                                <a href="#"><span className="fa fa-facebook"></span></a>
                                <a href="#"><span className="fa fa-twitter"></span></a>
                                <a href="#"><span className="fa fa-instagram"></span></a>
                            </div>
                        </div>
                    </div>
                    <div className="team-main-19">
                        <div className="column-19">
                            <a href="team-single.html"><img className="img-responsive" src="assets/images/te-4.jpg"
                                    alt=" "></a>
                        </div>
                        <div className="right-team-9">
                            <h6><a href="team-single.html" className="title-team-32">David Warner</a></h6>
                            <p className="sm-text-32">Manager</p>
                            <p className="sub-paragraph midd-para-team">Lorem ipsum dolor sit amet, consectetur adipisicing
                                elit</p>
                            <div className="buttons-teams">
                                <a href="#"><span className="fa fa-facebook"></span></a>
                                <a href="#"><span className="fa fa-twitter"></span></a>
                                <a href="#"><span className="fa fa-instagram"></span></a>
                            </div>
                        </div>
                    </div> --> */}
						</div>
					</div>
				</div>
			</section>
			{/* <!-- //teams 32 block --> */}
			<section className="w3l-content-with-photo-6">
				{/* <!-- /content-with-photo --> */}
				{/* <!-- <div className="content-with-photo">
            <div className="float-lt photo-inn">
                <h2>Consectetur Adipisicing elit</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.Lorem ipsum dolor sit
                    amet, consectetur adipisicing elit, sed do eiusmod tempor </p>
                <div className="content-w-grids">
                    <div className="sub-grid">
                        <h3>Parts and systems</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                    </div>
                    <div className="sub-grid">
                        <h3>Maintenance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>

                    </div>
                </div>
            </div>
            <div className="float-rt photo-img">
                <img src="assets/images/e5.jpg" className="img-responsive" alt="vv">
            </div>

        </div> --> */}

				{/* <!-- /content-with-photo --> */}
			</section>
		</>
	);
};

export default About;
