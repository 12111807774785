import React from 'react';
import InnerBanner from 'components/inner-banner';

const Contact = () => {
	return (
		<>
			{/* <!-- inner banner --> */}
			<InnerBanner title="Contact" />

			{/* <!-- contacts --> */}
			<section className="w3l-contacts-9-main">
				<div className="map">
					<iframe
						title="map"
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387193.3059445135!2d-74.25986613799748!3d40.69714941774136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m3!3e3!4m0!4m0!5e0!3m2!1sen!2sin!4v1570181661801!5m2!1sen!2sin"
						allowfullscreen="" />
				</div>
				<div className="contacts-9">
					<div className="wrapper">
						<div className="top-map">
							<div className="cont-details">
								<h4>Call Us or fill the form</h4>
								<div className="cont-top">
									<div className="cont-left">
										<span className="fa fa-mobile"></span>
									</div>
									<div className="cont-right">
										<h6>
											<a href="tel:+142 5897555">+142 5897555</a>
										</h6>
										<p>Lorem ipsum dolor sit amet,</p>
									</div>
								</div>
								<div className="cont-top">
									<div className="cont-left">
										<span className="fa fa-clock-o"></span>
									</div>
									<div className="cont-right">
										<h6>Working time</h6>
										<p>Mon - Fri: 9:00 - 19:00 / Closed on Weekends</p>
									</div>
								</div>
								<div className="cont-top">
									<div className="cont-left">
										<span className="fa fa-map-marker"></span>
									</div>
									<div className="cont-right">
										<h6>Company Headquarters</h6>
										<p>Lorem ipsum street, Newyork City</p>
									</div>
								</div>
							</div>
							<div className="map-content-9">
								<form action="https://sendmail.w3layouts.com/submitForm" method="post">
									<div className="twice-two">
										<input type="text" name="w3lName" id="w3lName" placeholder="Name" required="" />
										<input type="text" name="w3lName" id="w3lName" placeholder="Company" required="" />
									</div>
									<div className="twice-two">
										<input type="email" name="w3lSender" id="w3lSender" placeholder="Email" required="" />
										<input type="text" name="w3lName" id="w3lName" placeholder="Phone" required="" />
									</div>
									<textarea name="w3lMessage" id="w3lMessage" placeholder="Message" required="" />
									<button type="submit">Send</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Contact;
